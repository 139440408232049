import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Main from "../../components/Main";
import StructuredData from "../../components/StructuredData";

const IndexPage = ({ data }) => {
  const texts = data?.allContentfulWebsiteTexts?.nodes[0];
  const portfolio = data?.allContentfulPortfolio?.nodes;

  const { websiteTitle, seoDescription, seoImages } = texts;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "es",
        }}
      >
        <meta charSet="utf-8" />
        <title>{websiteTitle}</title>
        <meta name="description" content={seoDescription} />
      </Helmet>
      <StructuredData seoImages={seoImages} />

      <Main texts={texts} portfolio={portfolio} />
    </>
  );
};

export const query = graphql`
  query GetWebsiteTextsEs {
    allContentfulWebsiteTexts(filter: { node_locale: { eq: "es-AR" } }) {
      nodes {
        home
        scrollDown
        send
        skills
        skillsList
        software
        studio
        teamOne
        teamThree
        teamTwo
        touch
        works
        email
        getIn
        our
        boutique
        contact
        name
        message
        websiteTitle
        seoDescription
        companyDescription {
          raw
        }
        seoImages {
          url
        }
        formSuccessMessage {
          raw
        }
        formErrorMessage {
          raw
        }
      }
    }
    allContentfulPortfolio(filter: { node_locale: { eq: "es-AR" } }) {
      nodes {
        category
        description
        title
        url
        order
        image {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            width: 1024
          )
        }
      }
    }
  }
`;

export default IndexPage;
